import React from "react"
import ReactDOMServer from "react-dom/server"
import styled from "styled-components"
import { useHash } from "@utils"
import { graphql } from "gatsby"
import { Grid, Link, Margin, Picture } from "@components/atoms"
import { TransitionMask } from "@components/organisms"
import { TagProps, PageProps } from "@types"

interface ParsedTags {
  [key: string]: {
    color: string
    tags: {
      [key: string]: number
    }
  }
}

const TagsPage = ({
  data: {
    allSanityPost: { nodes },
    sanityPage: { extraTagsPageFields, backgroundColor, backgroundImage },
  },
}: TagsPageProps & PageProps) => {
  const [tagFromHash, setTag] = useHash()
  const [parsedTags, setParsedTags] = React.useState<ParsedTags>({})

  React.useEffect(() => {
    const newTags: ParsedTags = {}
    extraTagsPageFields.tagCategories?.forEach(c => {
      if (!Object.keys(newTags).includes(c.category)) {
        newTags[c.category] = { color: c.color.hex, tags: {} }
      }
    })
    nodes.forEach(node => {
      node.tags.forEach(t => {
        if (!newTags[t.tagSet.category]) return
        if (!Object.keys(newTags[t.tagSet.category].tags).includes(t.tag)) {
          newTags[t.tagSet.category].tags[t.tag] = 1
        } else {
          newTags[t.tagSet.category].tags[t.tag] =
            newTags[t.tagSet.category].tags[t.tag] + 1
        }
      })
    })
    setParsedTags(newTags)
  }, [setParsedTags, nodes, extraTagsPageFields])

  const [selectedTag, setSelectedTag] = React.useState<string>()
  const [selectedTagColor, setSelectedTagColor] =
    React.useState<string>("#ffffff")
  const [projectsWithSelectedTag, setProjectsWithSelectedTag] =
    React.useState<Array<any>>() // todo: set type project with tags

  const loadTag = (clickedTag: string) => {
    window.scrollTo({ behavior: "smooth", left: 0, top: 0 })
    setSelectedTag(clickedTag.replace("#", ""))
    setProjectsWithSelectedTag(
      nodes.filter(
        node =>
          node.tags.filter(
            nodeTags =>
              nodeTags.tag.replace(" ", "-") === clickedTag.replace(" ", "-")
          ).length > 0
      )
    )
    nodes.forEach(node =>
      node.tags.forEach(t => {
        if (
          t.tag.replace(" ", "-") ===
          clickedTag.replace("#", "").replace(" ", "-")
        ) {
          setSelectedTagColor(t.tagSet.color.hex)
        }
      })
    )
  }

  React.useEffect(
    () =>
      loadTag(
        decodeURIComponent(tagFromHash.replace(/_/g, " ").replace("#", ""))
      ),
    [tagFromHash.replace(/_/g, " ")]
  )

  return (
    <Main
      backgroundColor={backgroundColor?.hex ?? "#FFDD44"}
      backgroundImage={backgroundImage?.asset.url ?? undefined}
    >
      {selectedTag && projectsWithSelectedTag && (
        <TagResults {...{ selectedTagColor }}>
          <TagResultsHeader>
            <span>“</span>
            {decodeURIComponent(tagFromHash.replace(/_/g, " "))}
            <span>”</span>
            <CloseButton onClick={() => setTag("")}>&#10005;</CloseButton>
          </TagResultsHeader>
          <Margin>
            <ol>
              {projectsWithSelectedTag
                .sort((a, b) =>
                  a.title
                    .replace(/["“”]/, "")
                    .localeCompare(b.title.replace(/["“”]/, ""))
                )
                .map((p, i) => (
                  <TagResult key={`project_${i}`}>
                    <StyledGrid noPadding>
                      <PictureWrapper>
                        <Link to={`/${p.slug.current}`}>
                          <Picture
                            src={p.shareImage.asset.url}
                            dimensions={p.shareImage.asset.metadata.dimensions}
                            columns={2}
                            visible={true}
                          />
                        </Link>
                      </PictureWrapper>
                      <h2>
                        <Link to={`/${p.slug.current}`}>{p.title}</Link>
                      </h2>
                      <ul>
                        {p.tags
                          .sort((a: TagProps, b: TagProps) =>
                            a.tag
                              .replace(/["“”]/, "")
                              .localeCompare(b.tag.replace(/["“”]/, ""))
                          )
                          .map((t: TagProps, ii: number) => (
                            <ProjectTag
                              key={`project_tag_${ii}`}
                              background={t.tagSet.color.hex}
                              onClick={() => setTag(t.tag)}
                            >
                              {t.tag}
                            </ProjectTag>
                          ))}
                      </ul>
                    </StyledGrid>
                    <hr />
                  </TagResult>
                ))}
            </ol>
          </Margin>
        </TagResults>
      )}

      <TagCategories>
        {Object.keys(parsedTags).map((category, i) => (
          <TagCategory
            key={`category_${i}`}
            background={parsedTags[category].color}
          >
            <h3>{category}</h3>
            <ul>
              {Object.keys(parsedTags[category].tags)
                .sort((a, b) => a.localeCompare(b))
                .map((tag: any, ii: number) => {
                  const tagParts = ReactDOMServer.renderToString(
                    tag.replace(/_/g, " ")
                  )
                    .replace("<!-- -->", "")
                    .split(" ")
                  return (
                    <Tag
                      onClick={() => setTag(tag)}
                      key={`tag_${ii}`}
                      underlined={
                        tagFromHash.replace(/_/g, " ").replace("#", "") === tag
                      }
                    >
                      <span className="wiggles">
                        {tagParts.map((c: string, i: number) => (
                          <span key={`${tag.replace(/["“”]/, "")}_${i}`}>
                            <span>{c.replace(/&amp;/g, "&")}</span>
                            {i === tagParts.length - 1 && (
                              <sup> ({parsedTags[category].tags[tag]})</sup>
                            )}
                          </span>
                        ))}
                      </span>
                    </Tag>
                  )
                })}
            </ul>
          </TagCategory>
        ))}
      </TagCategories>

      <TransitionMask />
    </Main>
  )
}

const TagResults = styled.div<{ selectedTagColor: string }>`
  background-color: ${props => props.selectedTagColor};
  ol {
    margin: 0;
    padding: 0;
    list-style-type: none;
    font-size: 2.3vw;
    line-height: 1.1em;
  }
  padding-bottom: 3vw;
`

const TagResultsHeader = styled.h1`
  position: relative;
  text-align: center;
  padding: 1.5vw 0px 1.3vw 0px;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 7vw;
  @media only screen and (min-width: 768px) {
    padding: 0.9vw 20px 0.7vw 20px;
    font-size: 4vw;
  }
  line-height: 1.05em;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  span {
    font-family: "kessler-displayregular", sans-serif;
    line-height: 0;
  }
`

const CloseButton = styled.div`
  position: absolute;
  right: 20px;
  top: 0;
  cursor: pointer;
`

const PictureWrapper = styled.div`
  grid-column-start: 1;
  grid-column-end: span 3;
  grid-row-end: span 1;
  @media only screen and (min-width: 768px) {
    grid-row-end: span 1;
    grid-column-end: span 2;
  }
  border: 2px solid black;
  transition: transform 0.15s ease-in-out;
`

const TagResult = styled.li`
  &:hover ${PictureWrapper} {
    transform: rotateZ(5deg);
  }
  &:nth-of-type(even):hover ${PictureWrapper} {
    transform: rotateZ(-5deg);
  }
  h2 {
    grid-column: 4 / span 5;
    grid-row: 1 / span 1;
    margin: 0;
    padding: 0;
    line-height: 1.1em;
    font-size: 5.5vw;
    @media only screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;
    }
    @media only screen and (min-width: 768px) {
      grid-column: 3 / span 4;
      font-size: 2.3vw;
    }
  }
  ul {
    grid-column: 1 / span 8;
    grid-row: 2 / span 1;
    text-align: center;
    @media only screen and (min-width: 768px) {
      text-align: left;
      grid-row: 1 / span 1;
      grid-column: 7 / span 6;
    }
    margin: 0;
    padding: 0;
    list-style: none;
    line-height: 0;
    margin: 0;
    padding: 0;
  }
  hr {
    width: calc(100% + 6vw);
    border: 0;
    outline: 0;
    height: 2px;
    background: black;
    margin-top: 3vw;
    margin: 3vw 0 0 -3vw;
  }
  padding: 3vw 0 0 0;
  &:last-of-type {
    hr {
      display: none;
    }
  }
`

const StyledGrid = styled(props => <Grid {...props} />)`
  position: relative;
  align-items: flex-start;
  @media only screen and (max-width: 767px) {
    grid-template-columns: repeat(8, 1fr);
  }
`

const ProjectTag = styled.li<{ background: string }>`
  display: inline-block;
  margin: 2px;
  padding: 0 5px;
  border: 2px solid black;
  background-color: ${props => props.background};
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  font-size: 5.5vw;
  @media only screen and (min-width: 768px) {
    font-size: 1.6vw;
  }
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out;
  cursor: pointer;
  &:hover {
    color: ${props => props.background};
    background-color: black;
  }
`

const Main = styled.main<{ backgroundColor: string; backgroundImage?: string }>`
  position: relative;
  background-color: ${props => props.backgroundColor ?? "#fef3d9"};
  ${props =>
    props.backgroundImage && `background-image: url(${props.backgroundImage});`}
  background-size: 50vw auto;
  min-height: 100vh;
`

const TagCategories = styled.div`
  grid-column: 1 / span 12;
  column-gap: 0;
  column-count: 2;
  @media only screen and (min-width: 768px) {
    column-count: 3;
  }
  padding-bottom: 30vw;
  line-height: 1.2em;
  width: calc(100% + 2px);
  overflow: hidden;
`

const TagCategory = styled.div<{ background: string }>`
  break-inside: avoid;
  box-sizing: border-box;
  flex: 1;
  background-color: ${props => props.background};
  padding: 2vw 3vw;
  border: 2px solid black;
  margin-top: -2px;
  h3 {
    margin: 0 0 5px 0;
    font-size: 3rem;
  }
  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  margin-left: -2px;
  &:nth-child(1) {
    margin-top: 0px;
  }
`

const Tag = styled.li<{ underlined: boolean }>`
  margin-bottom: 0.25em;
  font-family: "NHaasGroteskDSW01-65Md", sans-serif;
  cursor: pointer;
  transition: color 0.15s ease-in-out;
  line-height: 0;
  sup {
    font-family: "kessler-displayregular", sans-serif;
    font-size: 60%;
  }
  .wiggles {
    line-height: 0;
    span {
      line-height: 1em;
      white-space: nowrap;
      span {
        text-decoration: ${props => (props.underlined ? "underline" : "none")};
      }
    }
  }
  cursor: pointer;
  font-size: 5.5vw;
  @media only screen and (min-width: 768px) {
    font-size: 2.85rem;
  }
`

interface TagsPageProps {
  data: {
    allSanityPost: {
      nodes: [
        {
          title: string
          slug: {
            current: string
          }
          tags: Array<TagProps>
        }
      ]
    }
    allSanityTagCategory: {
      nodes: Array<{
        category: string
        color: {
          hex: string
        }
      }>
    }
  }
}

export const query = graphql`
  query TagsQuery {
    sanityPage(slug: { current: { eq: "tags" } }) {
      ...pageFields
      extraTagsPageFields {
        tagCategories {
          category
          color {
            hex
          }
        }
      }
    }
    allSanityPost {
      nodes {
        title
        slug {
          current
        }
        shareImage {
          asset {
            url
            metadata {
              dimensions {
                width
                height
                aspectRatio
              }
            }
          }
        }
        tags {
          tag
          tagSet {
            category
            color {
              hex
            }
          }
        }
      }
    }
  }
`

export default TagsPage
